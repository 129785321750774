<template>
  <div class="login_container">
    <van-list v-for="(item, index) in list" :key="index">
      <div
        style="
          position: fixed;
          top: 0px;
          bottom: 22%;
          overflow-y: auto;
          width: 100%;
        "
      >
        <van-cell :title="'就诊人姓名：' + userName">
          <template slot="right-icon">
            <span style="color: red">未就诊</span>
          </template>
        </van-cell>
        <van-cell-group>
          <div style="width: 100%">
            <van-cell title="就诊信息" style="font-weight: 700"></van-cell>
            <van-cell title="就诊科室">{{ item.deptName }}</van-cell>
            <van-cell title="就诊类别">{{ item.reglevlName }}</van-cell>
            <van-cell title="就诊医生">{{ item.doctName }}</van-cell>
            <van-cell>
              <template slot="icon">预约时间</template>
              <template slot="default">
                <div class="slot_align">{{ item.regTime }}</div>
              </template>
            </van-cell>
            <van-cell>
              <template slot="icon">就诊时间</template>
              <template slot="default">
                <div class="slot_align">{{ item.seeTime }}</div>
              </template>
            </van-cell>
            <van-cell title="就诊序号">
              <span style="color: #57c4b7">{{ item.clinicNO }}</span>
            </van-cell>
          </div>
          <div style="width: 100%">
            <van-cell title="就诊人信息" style="font-weight: 700"></van-cell>
            <!-- <van-cell title="病历号">
              <span style="color:#57c4b7;">{{item.outPatientId}}</span>
            </van-cell>
            <van-cell title="就诊卡">
              <span style="color:#57c4b7;">{{item.markNo}}</span>
            </van-cell> -->
            <van-cell title="订单号">
              <span style="color: #57c4b7">{{ item.orderId }}</span>
            </van-cell>
            <van-cell title="费用">
              <span style="color: #57c4b7">{{ item.totalCost }}</span
              >元
            </van-cell>
          </div>
        </van-cell-group>
      </div>

      <div style="width: 100%; position: fixed; top: 78%; bottom: 0px">
        <van-cell center>
          <template slot="title">
            <van-button
              plain
              type="primary"
              style="
                width: 100%;
                border-radius: 8px;
                color: #57c4b7;
                border-color: #57c4b7;
                margin-bottom: 2%;
              "
              @click="onclickesc(item.clinicNO)"
              >取消挂号</van-button
            >
          </template>
          <template slot="label">
            <span style="color: #57c4b7"
              >注:请按就诊序号排队就诊，当日就诊时段有效，过号作废，挂号退费请到指定窗口办理</span
            >
          </template>
        </van-cell>
      </div>
      <div
        style="
          font-size: 12px;
          color: #d3d3d3;
          margin: 1% 40%;
          width: auto;
          height: 40px;
        "
      >
        <span>&copy;拓实银医</span>
      </div>
    </van-list>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import { Toast, Dialog } from "vant";
import store from "../../store";
var nowDate = new Date();
// const OperatorId = "000000";
// let regNos = "";
export default {
  data() {
    return {
      userName: "",
      CardNo: "",
      list: [],
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.CardNo = store.getters["store/cardNo"];

    this.getRegL();
  },
  methods: {
    async getRegL() {
      let regNos = store.getters["store/regNo"];
      // let patientid = store.getters["store/patientid"]; //声明病历号
      var Date = {
        year: nowDate.getFullYear(),
        month:
          nowDate.getMonth() + 1 < 10
            ? "0" + (nowDate.getMonth() + 1)
            : "" + (nowDate.getMonth() + 1),
        date:
          nowDate.getDate() < 10
            ? "0" + nowDate.getDate()
            : "" + nowDate.getDate(),
      };
      var Today = Date.year + "-" + Date.month + "-" + Date.date;
      let userId = store.getters["store/userId"];
      // let idcard = store.getters["store/idcard"];
      console.log(userId);
      await ajax
        .post("/Api/RegistrationService/QueryRegistRecords", {
          // userID: userId,
          userID: userId,
          // userName:this.userName,
          // state:[2,3],
          startDate: Today + " 00:00:00",
          endDate: Today + " 23:59:59",
        })
        .then((rep) => {
          // console.log(rep.data.result.regDetails);
          let listss = rep.data.result.regRecordLists;
          // for(let i=0;i<listss.length; i++){
          //   listss.scheduleDate = listss.scheduleDate.substring(0,10);
          //   if(listss.drId == ''){
          //     listss.regLevelName = '普通号'
          //     listss.drName = '门诊医生|医师'
          //   }else{
          //      listss.regLevelName = '专家号'
          //   }
          //   let name = listss.drName.split("|");
          //   listss.docname = name[0];
          // // }
          // // this.list = listss;
          // console.log(listss);
          for (let i = 0; i < listss.length; i++) {
            const element = listss[i];
            if (element.clinicNO == regNos) {
              this.list.push(element);
            }
          }

          // console.log(listss);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclickesc(clinicNO) {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定取消挂号",
      })
        .then(async () => {
          await ajax
            .get("/Api/RegistrationService/RegRefund?input=" + clinicNO)
            .then(async (res) => {
              console.log(res.data.result);
              if (res.data.result === 1) {
                Toast("您已成功退号，退款正在受理中，请注意查收");
                this.getRegL();
              } else if (res.data.result === -1) {
                Toast("订单不存在");
              } else if (res.data.result === -2) {
                Toast("his返回失败");
              } else {
                Toast("申请退款失败，请到窗口详询");
              }
            })
            .catch((err) => {
              console.log(err.message);
              Toast(err.message);
            });
        })
        .catch(() => {
          Toast("您取消了取消挂号!");
        });
      // },
      //    async getMenu() {
      //     var that=this;
      //   await ajax.get("/api/TokenAuth/GetExternalAuthenticationProviders")
      //   .then(function(response){
      //      that.list =response.data.result;
      //   },function(err){
      //        alert("");
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
.slot_align {
  color: #969799;
  text-align: right;
}
</style>
